
/* Home.css */
/* Stores styles for the `/` components */

* {
    font-family: 'Lato', sans-serif;
}

body {
    background-color: #FAF3DD;
}

.login {
    position: absolute;
    top: 45%;
    left: 50%;
    text-align: center;
    margin: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.logo {
    width: 100%;
    text-align: center;
    top: 0;
    position: absolute;
}

.logo img {
    width: 4vmax;
    height: 4vmax;
    margin-top: 6vh;
}

.login h1 {
    font-family: 'Playfair Display', serif;
    font-size: 4.5vmax;
    padding-bottom: 0.25vmax;
}

.login h3 {
    font-family: 'Lato', sans-serif;
    font-size: 2vmax;
    font-weight: 300;
    padding-bottom: 2vmax;
}

.login i {
    font-size: 1.75vmax;
    display: inline-block;
}

.login input {
    background-color: #000000;
    border: none;
    color: #FAF3DD;
    padding: 0.5vmax;
    font-size: 1.33vmax;
    margin-bottom: 1vmax;
    width: 19vmax;
    height: 1.75vmax;
    margin-left: 1.5vmax;
}

.login input:focus {
    outline: none;
}

.login a i {
    font-size: 1.5vmax;
    color: #FAF3DD;
    width: 2vmax;
    display: inline-block;
    background-color: #000000;
    padding: 0.675vmax 0.4vmax;
    border-radius: 100px;
}

.error-modal {
    width: 25%;
    height: 15vh;
    background-color: #000;
    border: 2px #FAF3DD solid;
    box-shadow: #000 0px 0px 20px 2px;
    color: #FAF3DD;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 1vmax;
    z-index: 1000;
}

.error-modal p {
    margin-top: 1vmax;
}

.error-modal a {
    text-decoration: none;
    color: #FAF3DD;
    border: 1px #FAF3DD solid;
    background-color: #000;
    padding: 0.5vmax 1.5vmax;
    display: inline-block;
    margin-top: 0.5vmax;
    transition: color 0.1s, background-color 0.1s;
}

.error-modal a:hover {
    background-color: #FAF3DD;
    color: #000;
}