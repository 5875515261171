
/* index.css */
/* Grabs fonts and prestyling information */

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;500&display=swap');

.noscroll::-webkit-scrollbar {
    display: none;
}
  
.noscroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar { width: 0 !important }
* { overflow: -moz-scrollbars-none; }
* { -ms-overflow-style: none; }